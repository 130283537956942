/* !
 * This file is a part of proprietary software.
 * Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
 */

domReady(() => {
	var toggle = document.getElementById("menutoggle");
	if(toggle){
		toggle.addEventListener("click", setActive);
	}

	function setActive(){
		if(toggle.classList.contains("is-active")){
			toggle.classList.remove("is-active");
		}else{
			toggle.classList.add("is-active");
		}
	}

	let menu = document.getElementById('menu');
	if(menu){
		menu.addEventListener('hidden', (e) => {
			if (toggle.classList.contains("is-active") && menu.isEqualNode(e.target)) {
				toggle.classList.remove("is-active");
			};
		})
	}
});
