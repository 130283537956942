async function refreshCaptcha(base){
    fetch(`${base}/3_libraries/CaptchaHelper.php?request=refresh`)
    .then(response => response.json())
    .then(data => {
        let captchaImgs = document.getElementsByClassName('captcha-img');
        for(captchaImg of captchaImgs){
            captchaImg.src = data.binary;
        }
    })
    .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
    });
}

async function captchaTTS(base){
    fetch(`${base}/3_libraries/CaptchaHelper.php?request=speak`)
    .then(response => response.json())
    .then(data => {
        let tts = new SpeechSynthesisUtterance;
        tts.text = atob(data.binary);
        tts.lang = 'de-DE';
        tts.volume = 0.5;
        tts.rate = 0.8;
        tts.pitch = 1;
        speechSynthesis.speak(tts);
    })
    .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
    });
}