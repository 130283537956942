let bookingExists = document.getElementById("booking");
if (bookingExists) {
	const booking = new Vue({
		el: '#booking',
		name: 'Booking',
		delimiters: ['<%', '%>'],
		watch: {
			roomcount: function () {
				let arrLength = this.guestsPerRoom.length;
				let count = parseInt(this.roomcount);
				if (count < arrLength) {
					let diff = arrLength - count;
					for (let i = 0; i < diff; i++) {
						this.guestsPerRoom.pop();
					}
				} else if (count > arrLength) {
					let diff = count - arrLength;
					for (let i = 0; i < diff; i++) {
						this.guestsPerRoom.push({
							adults: 1,
							children: 0,
							childrenAges: [0]
						});
					}
				}
			},
			guestsPerRoom: {
				handler: function () {
					for (let j = 0; j < this.guestsPerRoom.length; j++) {
						let count = parseInt(this.guestsPerRoom[j].children);
						let arrLength = this.guestsPerRoom[j].childrenAges.length;
						if (count < arrLength) {
							let diff = arrLength - count;
							for (let i = 0; i < diff; i++) {
								this.guestsPerRoom[j].childrenAges.pop();
							}
						} else if (count > arrLength) {
							let diff = count - arrLength;
							for (let i = 0; i < diff; i++) {
								this.guestsPerRoom[j].childrenAges.push(0);
							}
						}
					}
				},
				deep: true
			},
			checkin: function(){
				if(Array.isArray(this.block)){
					var check = Date.parse(this.checkin);
					this.block.forEach(e => {
						let start = Date.parse(e[0]);
						let end = Date.parse(e[1]);
						if (check <= end && check >= start) {
							this.checkin = Date;
							UIkit.notification({
								message: '<span uk-icon=\'icon: warning\'></span> Das gewählte Datum ist als Anreisedatum nicht verfügbar.',
								status: 'warning'
							})
						}
					}, this);
				}
			}
		},
		data: {
			today: Date,
			tomorrow: Date,
			checkin: Date,
			checkout: Date,
			roomcount: 1,
			guestsPerRoom: [{
				adults: 1,
				children: 0,
				childrenAges : []
			}],
			special: false,
			specialName : '',
			min: Date,
			max: Date,
			range: {
				start: null,
				end: null
			},
			block: Array
		},
		methods: {
			reset: function () {
				this.roomcount = 1;
				this.guestsPerRoom = [{
					adults: 1,
					children: 0,
					childrenAges: []
				}];
			},
			submit: function (e) {
				let url = 'https://api.direct-reservation.net/';
				let websiteID = 1403;
				let returnURL = `${url}?websiteID=${websiteID}&query=${escape(JSON.stringify(this.apiObject))}&modules=${JSON.stringify(['Accommodation'])}`;
				window.open(returnURL, '_blank');
				e.preventDefault();
			},
			applySpecial: function(e){
				this.special = e.detail.special;
				this.checkin = e.detail.begin;
				this.checkout = e.detail.end;
				this.specialName = e.detail.name;
				this.range.start = e.detail.range_start;
				this.range.end = e.detail.range_end;
				this.block = JSON.parse(e.detail.block);
			}
		},
		computed: {
			roomCountAsInt() {
				return parseInt(this.roomcount);
			},
			apiObject(){
				let guests = JSON.parse(JSON.stringify(this.guestsPerRoom));
				guests.forEach(value => {
					value.adults = parseInt(value.adults);
					delete value.children
				});
				return {
					Accommodation: {
						AgentPassword: undefined,
						AgentUsername: undefined,
						DestinationCodes: ['CHM'],
						EstablishmentIDs: [],
						PromoCode: undefined
					},
					CurrencyCode : "EUR",
					DateFrom : new Date(this.checkin),
					DateTo : new Date(this.checkout),
					LanguageCode: "DE",
					PassengerGroups: guests,
					//from here all null, as not needed
					Tours: undefined,
					Transfers: undefined,
					Flights: undefined
				}
			}
		},
		mounted: function () {
			if(!this.special){
				let today = new Date();
				let dd = String(today.getDate()).padStart(2, '0');
				let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				let yyyy = today.getFullYear();
				this.today = today;

				let tomorrow = new Date(today);
				tomorrow.setDate(tomorrow.getDate() + 1);
				let dd2 = String(today.getDate() + 1).padStart(2, '0');
				this.tomorrow = tomorrow;

				this.checkin = `${yyyy}-${mm}-${dd}`;
				this.checkout = `${yyyy}-${mm}-${dd2}`;
			}
			let honey = document.getElementById('honey');
			honey.parentNode.removeChild(honey);
		}
	});
}
