let kennenlernExists = document.getElementById("booking_options");
if (kennenlernExists){
    const kennenlernBookingOptions = new Vue({
        el: '#booking_options',
        name: 'Kennenlern Booking Options',
        delimiters: ['<%', '%>'],
        data: {
            id: 69766
        },
        computed: {
            link: function(){
                return `https://tannenhaus.direct-reservation.net/de/accommodation/tannenhaus-hotel--restaurant/rateplans/${this.id}`;
            }
        }
    });
}